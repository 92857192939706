export default {
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "naanMudhalvan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAAN MUDHALVAN"])},
  "loginTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "forgotUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Username?"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "enterUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Username"])},
  "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captcha"])},
  "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send OTP"])},
  "otpSentTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP sent to"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "invalidAdharNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Adhar Number"])},
  "yearOfPassout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Passout"])},
  "verifyResetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify & Reset Password"])},
  "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password"])},
  "enterRegisteredEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Registered Email"])},
  "forgotPasswordHelpVerifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your email and reset the password."])},
  "forgotPasswordHelpVerifyUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your username and reset the password."])},
  "forgotUsernameHelpVerifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your registered mail id we will send the username to your mail."])},
  "sendUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Username"])},
  "captchaImageHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the characters on image"])},
  "studentRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Registration"])},
  "registrationHelp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the following instructions before filling this form"])},
  "registrationHelpLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid email address and contact number to register with Naan Mudhalvan."])},
  "registrationHelpLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All official information / communication will ONLY be sent to the Email Address and contact number provided during the registration."])},
  "registrationHelpLine3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denotes that it is mandatory to fill."])},
  "registrationHelpLine4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure all the relevant fields are accurately filled."])},
  "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Details"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
  "discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinued"])},
  "yearOfDiscontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Discontinued"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "emailId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ID"])},
  "selectCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Category"])},
  "communitySelectionHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid caste certificate to get 50% fee concession"])},
  "uploadCasteCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Caste Certificate"])},
  "uploadValidPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload valid PDF / Image file"])},
  "academicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Details"])},
  "collegeRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register No"])},
  "studentRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register No"])},
  "branchSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch / Specialization"])},
  "selectedBranchSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Branch / Specialization"])},
  "yearOfJoining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Joining"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "permanentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent Address"])},
  "enterAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
  "villageCityTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village / City / Town"])},
  "pinCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Code"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select State"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "enterDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter District"])},
  "currentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Address"])},
  "sameAsPermanentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as permanent address"])},
  "loginDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Details"])},
  "createUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your username"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "colleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colleges"])},
  "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College"])},
  "invitedColleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited Colleges"])},
  "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "mandatoryCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory Course"])},
  "financialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year"])},
  "masters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masters"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "courseEnrolments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Enrolments"])},
  "validateCasteCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate Caste Certificate"])},
  "validatePassoutStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate Passout Students"])},
  "StudentCourseSelectionsDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Course Selections Details"])},
  "addStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Student"])},
  "enrolledCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrolled Courses"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
  "psychometricTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychometric Test"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "collegeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Details"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
  "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "courseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name"])},
  "totalVideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Videos"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "createdOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created On"])},
  "updatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated On"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty"])},
  "allDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Districts"])},
  "typeOfInstitution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Institution"])},
  "resendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Invite"])},
  "editResendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit & Resend Invite"])},
  "totalInviteColleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Invite Colleges"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered"])},
  "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited"])},
  "collegeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Name"])},
  "institutionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Type"])},
  "invitedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited On"])},
  "searchStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Students"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CSV"])},
  "totalResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Results"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "studentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Details"])},
  "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
  "uploadAFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
  "orDragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])},
  "CSVFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV File"])},
  "fileUploadedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded successfully"])},
  "checkCSVDetailsBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the uploaded CSV file details in below"])},
  "wantToSendInvitePopMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send invitation to selected college(s)"])},
  "wantToSendStudentInvitePopMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send invitation to selected students(s)"])},
  "sendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invitation"])},
  "emailInvitationsInitiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email invitations initiated"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data."])},
  "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
  "trainingModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Module"])},
  "trainingLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Level"])},
  "specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
  "yearOfStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of study"])},
  "modeOfDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode of Delivery"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
  "offeredSubsidizedCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered at a subsidized/discounted cost to eligible students"])},
  "bulkImportStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Import Students"])},
  "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested"])},
  "collegeApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Approved"])},
  "collegeRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Rejected"])},
  "knowledgePartnerPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Partner Pending"])},
  "knowledgePartnerApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Partner Approved"])},
  "knowledgePartnerRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Partner Rejected"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
  "caste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caste"])},
  "provisional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisional"])},
  "offlineCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline courses"])},
  "onlineCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online courses"])},
  "courseProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Progress"])},
  "progressPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress Percentage"])},
  "courseCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Completed"])},
  "assessmentCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Completed"])},
  "issueCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue Certificate"])},
  "viewCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Certificate"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "noEnrolledCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No enrolled courses"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enroll"])},
  "enrollOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enroll Online"])},
  "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
  "openings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openings"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "openPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Positions"])},
  "viewProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Progress"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "update_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update/Approve"])},
  "updateCourseProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Course Progress"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "certificationInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification in progress"])},
  "targetAudience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Audience"])},
  "trainingPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Partner"])},
  "moreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More details"])},
  "whatYouLearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you'll learn"])},
  "courseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Content"])},
  "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements"])},
  "noJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Jobs in current company."])},
  "noCompaniesInSector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Companies in current sector."])},
  "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Failed"])},
  "paymentFailedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment has been failed."])},
  "paymentSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Successful"])},
  "paymentSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment has been processed. Details of transactions are included below"])},
  "transactionTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Time"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Id"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
  "2022GraduatesRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 Graduates Registration"])},
  "aadhaarNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aadhaar Number"])},
  "uploadProvisionalDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Provisional Degree Certificate"])},
  "skillOfferings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill Offerings"])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
  "finishingSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finishing School"])},
  "finishingSchoolEnrolments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finishing School Enrolments"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollments"])},
  "waitingForApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for approval"])},
  "mandatoryCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory Courses"])},
  "facultyNomination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Nomination"])},
  "facultyMapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Mapping"])},
  "facultyFeedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Feedbacks"])},
  "faculties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculties"])},
  "finishingSchoolDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naan Mudhalvan platform aims to create constant opportunities for the youth of Tamil Nadu to be able to acquire skills of choice and to support them with the means of creating sustainable livelihoods<br/>Finishing school aims at empowering students with various skills sets in addition to Knowledge that makes them Industry ready. Finishing school trainers enable to hone their life skills employability skills, functional and spoken English skills to carve niche in the competitive and dynamic world.<br/>Finishing schools additionally include workshops to get the candidates skilled in the use of some software skills considered to be a crucial part of their daily expert life"])},
  "pendingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending approval"])},
  "pleaseWaitTillApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait till approval"])},
  "courseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Type"])},
  "stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream"])},
  "freeCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Course"])},
  "paidCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Course"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
  "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
  "editStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Student"])},
  "unverifiedStudentsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverified Students Count"])},
  "semesterRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester field is required and should be a number"])},
  "updateAndVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update, Verify and Send Invite"])},
  "addAndVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add, Verify and Send Invite"])},
  "finishStudentVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Student Verification"])},
  "verificationCompleteRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student verification requests has been complete."])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
  "pendingVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending verification requests"])},
  "pendingStudentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify the pending students before finishing the verification."])},
  "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
  "collegesVerifiedCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colleges Verified Count"])},
  "pendingVerificationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Verification"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "rollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register No"])},
  "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Email"])},
  "invalidMobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Mobile Number"])},
  "deleteStudentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this student?"])},
  "noStudentsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No students found"])},
  "verifyStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Students"])},
  "courseSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Selections"])},
  "placementDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement Details"])},
  "exportCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export CSV"])},
  "downloadCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Completed List"])},
  "completedCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Count"])},
  "collegeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College ID"])},
  "downloadPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Pending List"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "bulkUploadConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to verify"])},
  "studentDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student deleted"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "noSuchOfferings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No such offering in this selection"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "yearOfGraduation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of graduation"])},
  "finishingSchoolRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finishing School Registration"])},
  "finishingSchoolPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finishing School Partner"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "industries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industries"])},
  "trainingPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Partners"])},
  "courseSyllabus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Syllabus"])},
  "organisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Name"])},
  "industryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry Type"])},
  "primaryContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact"])},
  "primaryContact2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact 2"])},
  "hasInternships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Internships"])},
  "hasJobOpenings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Job Openings"])},
  "internshipTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship Title"])},
  "internshipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship Type (Virtual / In Person)"])},
  "internshipTypeOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship Type"])},
  "eligibleCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligible Criteria"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "durationMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (Months)"])},
  "noOfOpenings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No of Openings"])},
  "lastDateOfApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last date of application"])},
  "skillsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills Required"])},
  "freeOrPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free / Paid"])},
  "ifPaidStipend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If Paid, Stipend details"])},
  "stipend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipend"])},
  "otherPerks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Perks"])},
  "aboutTheInternship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the internship"])},
  "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "taluk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taluk"])},
  "educationalQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Qualification"])},
  "qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
  "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
  "jobDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
  "trainingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Title"])},
  "trainingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Description"])},
  "mentorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentor Name"])},
  "mentorEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentor Email"])},
  "mentorPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentor Phone Number"])},
  "designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])},
  "availabilityHoursWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability (Hours / week)"])},
  "modeVirtualInPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode (Virtual / In Person)"])},
  "linkedinProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin Profile URL"])},
  "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
  "addJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Job"])},
  "addInternship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Internship"])},
  "addMentorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Mentorship"])},
  "internships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internships"])},
  "mentorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentorship"])},
  "addTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Training"])},
  "trainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainings"])},
  "editCollege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit College"])},
  "spocName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPOC Name"])},
  "updateCollege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update College"])},
  "addCollege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add College"])},
  "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "addMC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Mandatory Course"])},
  "addUpdateMC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add / Update Mandatory Course"])},
  "collegeAddedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College added successfully"])},
  "collegeUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College updated successfully"])},
  "pleaseFillAllFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all the fields"])},
  "zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone"])},
  "collegeCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Code"])},
  "pincode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pincode"])},
  "doYouWantToImportStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to import students(s)"])},
  "studentsUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload initiated Please check the status in the student upload history"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "bulkStudentsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Students History"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
  "readyToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Upload"])},
  "invalidCollegeCodesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid College Codes Count"])},
  "invalidBranchCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Branch Count"])},
  "invalidRollNoCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Roll No Count"])},
  "successRecordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Records Count"])},
  "failedRecordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed Records Count"])},
  "uploadedFileDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded File Details"])},
  "invalidCollegeCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid College Codes"])},
  "invalidRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Roll No"])},
  "invalidBranchIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Branch Ids"])},
  "unVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverified"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Email"])},
  "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Email"])},
  "updatePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Phone"])},
  "newPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Phone number"])},
  "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "sem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "studentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
  "finishAllocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Allocations"])},
  "temporaryRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Reg No"])},
  "proceedToCoursera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to coursera"])},
  "proceedToInfosys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to Infosys"])},
  "accessCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Course"])},
  "proceedToMicrosoft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to Microsoft"])},
  "knowledgePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Partner"])},
  "allocated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated"])},
  "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
  "updateExternalAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update External Assessment"])},
  "absentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent for"])},
  "assessment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment 1"])},
  "assessment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment 2"])},
  "assessment3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment 3"])},
  "assessment4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment 4"])},
  "assessment5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment 5"])},
  "updateAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Assessment"])},
  "externalAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
  "externalAssessment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
  "Assessment Rubics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Rubrics"])},
  "finishExternalAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish External Assessment"])},
  "finishExternalAssessmentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to finish the external assessment?"])},
  "externalAssessmentFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External assessment finished"])},
  "enterPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Phone Number"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
  "eoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EOI"])},
  "contactPersonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Name"])},
  "contactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Email"])},
  "contactPersonPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Phone"])},
  "registrationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Document"])},
  "proposalDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposal Document"])},
  "declarationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration Document"])},
  "submittedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted On"])},
  "uploadRegistrationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Registration Document"])},
  "uploadOnlyPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload only PDF"])},
  "uploadProposalDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Proposal Document"])},
  "uploadDeclarationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Declaration Document"])},
  "sepcialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
  "sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors"])},
  "expressionOfInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expression of Interest"])},
  "viewDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View / Download"])},
  "reviewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Course"])},
  "reviewCourseToViewCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Course to view certificate"])},
  "assessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments"])},
  "courseraAssessmentUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coursera Assessment Upload"])},
  "courseraAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coursera Assessments"])},
  "uploadCourseraAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Coursera Assessment"])},
  "doYouWantToUploadCourseraAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to upload coursera assessment(s)"])},
  "courseraAssessmentUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload initiated Please check the status in the coursera assessment upload history"])},
  "uploadMicrosoftAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Microsoft Assessment"])},
  "doYouWantToUploadMicrosoftAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to upload microsoft assessment(s)"])},
  "microsoftAssessmentUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload initiated Please check the status in the microsoft assessment upload history"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "partiallyUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially Uploaded"])},
  "placementStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement Profile Update"])},
  "jobPortalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Portal"])},
  "StudentCourseSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Course Selection Form"])},
  "microsoftAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Assessments"])},
  "microsoftUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Update"])},
  "assessmentSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Serial"])},
  "assessmentRubrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Rubrics"])},
  "refAssessmentRubrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please refer Assessment Rubrics before uploading the Internal Marks"])},
  "updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updates"])},
  "infosysAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infosys Assessments"])},
  "uploadInfosysAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Infosys Assessment"])},
  "doYouWantToUploadInfosysAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to upload infosys assessment(s)"])},
  "infosysAssessmentUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload initiated Please check the status in the infosys assessment upload history"])},
  "assessmentPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Percentage"])},
  "addFaculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Faculty"])},
  "facultyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Data"])},
  "noFacultyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No faculty data"])},
  "facultyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Details"])},
  "updateFacultyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Faculty Details"])},
  "collegeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Data"])},
  "psychometricStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychometric Status"])},
  "principalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Data"])},
  "principalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Details"])},
  "principalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Name"])},
  "principalEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Email"])},
  "principalPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Phone"])},
  "updatePrincipalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Principal Details"])},
  "internalAssessmentMarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Assessment Marks"])},
  "externalAssessmentMarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Assessment Marks"])},
  "placementOfficerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement Officer Details"])},
  "updatePlacementOfficerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Placement Officer Details"])},
  "costPerStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost Per Student"])},
  "enterOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter OTP"])},
  "foundMultipleUsersPleaseEnterRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found multiple users please enter last 4 digits roll no"])},
  "verifyOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify OTP"])},
  "studentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Login"])},
  "last4DigitsRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 4 digits of college roll no"])},
  "deleteFacultyConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the faculty?"])},
  "facultyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Id"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Active"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "courseOption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Selection 1"])},
  "courseOption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Selection 2"])},
  "courseOption3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Selection 3"])},
  "placementsDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement Details"])},
  "placements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placements"])},
  "overviewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview Report"])},
  "downloadPlacementReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Reports"])},
  "placementReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement Reports"])},
  "districtsOverviewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Districts Overview Report"])},
  "placementsOverviewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placements Overview Report"])},
  "placementsDistrictOverviewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placements District Overview Report"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Image"])},
  "selectAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Any"])},
  "passout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passout"])},
  "collegeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Type"])},
  "studentCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Count"])},
  "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
  "maxStudentCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Student Count"])},
  "mcUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory Course Updated Successfully"])},
  "mcAddedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory Course Added Successfully"])},
  "addNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Notification"])},
  "addBankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Bank Details"])},
  "editBankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Bank Details"])},
  "accountHolderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Holder Name"])},
  "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
  "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
  "branchName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Name"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
  "ifscCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IFSC Code"])},
  "totalNoActiveStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Number Of Active Students"])},
  "invalidAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Account Number"])},
  "unallocated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unallocated"])},
  "currentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Status"])},
  "spocBankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoc Bank Details"])},
  "sendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invite"])},
  "studentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Status"])},
  "updateBankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Bank Details"])},
  "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Details"])},
  "totalColleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Colleges"])},
  "totalStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Students"])},
  "passoutStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass Out Students"])},
  "activeStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Students"])},
  "discontinuedStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinued Students"])},
  "longAbsentStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long Absent Students"])},
  "duplicateStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Students"])},
  "sem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem 1"])},
  "sem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem 2"])},
  "sem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem 3"])},
  "sem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem 4"])},
  "sem5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem 5"])},
  "sem6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem 6"])},
  "placementOfficerData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement Officer Data"])},
  "addPlacementOfficer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Placement Officer"])},
  "changeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status"])},
  "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inavlid Name"])},
  "passoutYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passout Year"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Id"])},
  "collegeTypeWise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Type Wise"])},
  "studentCurrentStatusWise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Current Status Wise"])},
  "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "trainingPartnersLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Partners Logo"])},
  "educationalInstituteTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Institute Types"])},
  "studentStatusAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Status Analysis"])},
  "partiallyRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially Rejected"])},
  "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University"])},
  "facultyCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Certificate"])},
  "branchWise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Wise"])},
  "collegeWise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Wise"])},
  "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter By"])},
  "allUniversities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Universities"])},
  "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Categories"])},
  "userAddedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Added Successfully"])},
  "userUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Updated Successfully"])},
  "collegeWiseCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Wise Count"])},
  "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download All"])},
  "nmId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NM ID"])},
  "emisNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMIS Number"])},
  "umisNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UMIS Number"])},
  "transferStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Student"])},
  "passedOutYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed Out Year"])},
  "academicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])}
}