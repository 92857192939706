export default {
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெளியேறுக"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள் நுழைக"])},
  "naanMudhalvan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நான் முதல்வன்"])},
  "loginTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள் நுழைக"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயனர் பெயர்"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல்"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை உறுதிப்படுத்தவும்"])},
  "forgotUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயனர் பெயர் மறந்து விட்டதா?"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல் மறந்து விட்டதா?"])},
  "enterUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் பயனர்பெயரை உள்ளிடவும்"])},
  "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["காப்ட்ச்சா"])},
  "sendOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒருமுறை கடவுச்சொல் அனுப்பவும்"])},
  "otpSentTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒருமுறை கடவுச்சொல் அனுப்பப்பட்டது"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒருமுறை கடவுச்சொல்"])},
  "verifyResetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை சரிபார்த்து மீட்டமைக்கவும்"])},
  "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதிய கடவுச்சொல்லை உள்ளிடவும்"])},
  "enterRegisteredEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு செய்யப்பட்ட மின்னஞ்சலை உள்ளிடவும்"])},
  "forgotPasswordHelpVerifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் மின்னஞ்சலைச் சரிபார்த்து கடவுச்சொல்லை மீட்டமைக்கவும்"])},
  "forgotPasswordHelpVerifyUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் பயனர்பெயரை சரிபார்த்து கடவுச்சொல்லை மீட்டமைக்கவும்"])},
  "forgotUsernameHelpVerifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் பதிவு செய்யப்பட்ட அஞ்சல் ஐடியை நிரப்பவும், உங்கள் மின்னஞ்சலுக்கு பயனர் பெயரை அனுப்புவோம்."])},
  "sendUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயனர் பெயரை அனுப்பவும்"])},
  "captchaImageHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தயவுசெய்து காப்ட்ச்சா வடிவங்களை உள்ளிடவும்"])},
  "studentRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர் பதிவு"])},
  "registrationHelp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இந்தப் படிவத்தை நிரப்புவதற்கு முன் பின்வரும் வழிமுறைகளைப் படிக்கவும்"])},
  "registrationHelpLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நான் முதல்வனிடம் பதிவு செய்ய சரியான மின்னஞ்சல் முகவரி மற்றும் தொடர்பு எண்ணை வழங்கவும்"])},
  "registrationHelpLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனைத்து அதிகாரப்பூர்வ தகவல்களும்/தொடர்புகளும் பதிவு செய்யும் போது வழங்கப்பட்ட மின்னஞ்சல் முகவரி மற்றும் தொடர்பு எண்ணுக்கு மட்டுமே அனுப்பப்படும்."])},
  "registrationHelpLine3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புலங்களை பூர்த்தி செய்வது கட்டாயமானது"])},
  "registrationHelpLine4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர்புடைய அனைத்து புலங்களும் துல்லியமாக பூர்த்தி செய்யப்பட்டுள்ளதா என்பதை உறுதிப்படுத்தவும்"])},
  "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தனிப்பட்ட விவரங்கள்"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதல் பெயர்"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடைசி பெயர்"])},
  "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பிறந்த தேதி"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாலினம்"])},
  "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அலைபேசி எண்"])},
  "emailId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல் முகவரி"])},
  "selectCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பிரிவைத் தேர்வு செய்க"])},
  "communitySelectionHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50% கட்டணச் சலுகையைப் பெற சரியான சாதிச் சான்றிதழை வழங்கவும்"])},
  "uploadCasteCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஜாதி சான்றிதழை பதிவேற்றவும்"])},
  "uploadValidPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரியான PDF / படக் கோப்பைப் பதிவேற்றவும்"])},
  "academicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்வி விவரங்கள்"])},
  "collegeRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி ரோல் நம்பர்"])},
  "studentRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll No"])},
  "branchSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சிறப்பு பாடப்பிரிவு"])},
  "selectedBranchSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேர்ந்தெடுக்கப்பட்ட சிறப்பு பாடப்பிரிவு"])},
  "yearOfJoining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இணைந்த ஆண்டு"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆண்டு"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முகவரி"])},
  "permanentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிரந்தர முகவரி"])},
  "enterAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முகவரியை உள்ளிடவும்"])},
  "villageCityTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கிராமம்/நகரம்/ டவுண் உள்ளிடுக"])},
  "pinCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பின்கோடு "])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாநிலம்"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாநிலத்தைத் தேர்ந்தெடுக்கவும்"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாவட்டம்"])},
  "enterDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாவட்டத்தை உள்ளிடவும்"])},
  "currentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்போதைய முகவரி"])},
  "sameAsPermanentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அதே நிரந்தர முகவரி"])},
  "loginDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள்நுழைவு விவரங்கள்"])},
  "createUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் பயனர்பெயரை உருவாக்கவும்"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சமர்ப்பிக்கவும்"])},
  "colleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரிகள்"])},
  "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி"])},
  "invitedColleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழைக்கப்பட்ட கல்லூரிகள்"])},
  "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர்கள்"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர்"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடங்கள்"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடம்"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அறிக்கைகள்"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அறிக்கை"])},
  "courseEnrolments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடப் பதிவுகள்"])},
  "validateCasteCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சாதிச் சான்றிதழைச் சரிபார்க்கவும்"])},
  "validatePassoutStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேர்ச்சி பெற்ற மாணவர்களை சரிபார்க்கவும்"])},
  "StudentCourseSelectionsDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Course Selections Details"])},
  "addStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவரை சேர்க்கவும்"])},
  "enrolledCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவுசெய்யப்பட்ட படிப்புகள்"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பணிகள்/வேலைகள்"])},
  "psychometricTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["திறனறித்தேர்வு"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சுயவிவரம்"])},
  "collegeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி விவரங்கள்"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிலை"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனைைத்தும்"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதியவை"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அங்கீகரிக்கப்பட்டது"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒப்புதல்"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிராகரிக்கப்பட்டது"])},
  "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிராகரித்தல்"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெற்றி"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தோல்வி"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிலுவையில் உள்ள"])},
  "courseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடப் பெயர்"])},
  "totalVideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்த காணொளிகள்"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வாடிக்கையாளர்"])},
  "createdOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உருவாக்கப்பட்ட நாள்"])},
  "updatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதுப்பிக்கப்பட்ட நாள்"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செயல்கள்"])},
  "allDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனைத்து மாவட்டங்கள்"])},
  "typeOfInstitution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிறுவனத்தின் வகை"])},
  "resendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழைப்பை மீண்டும் அனுப்புதல்"])},
  "editResendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழைப்பைத் திருத்தி மீண்டும் அனுப்பவும்"])},
  "totalInviteColleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்த அழைப்பு கல்லூரிகள்"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ரத்து செய்தல்"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செயல்படுத்துதல்"])},
  "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு செய்யப்பட்டவை"])},
  "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழைக்கப்பட்டவை"])},
  "collegeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரியின் பெயர்"])},
  "institutionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிறுவனத்தின் வகை"])},
  "invitedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழைக்கப்பட்ட நாள்"])},
  "searchStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர்களைத் தேடுங்கள்"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேடு"])},
  "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV ஐப் பதிவேற்றவும்"])},
  "totalResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்த முடிவுகள்"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முழுப் பெயர்"])},
  "studentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவ விவரங்கள்"])},
  "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பட்டம்"])},
  "uploadAFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கோப்பைப் பதிவேற்றவும்"])},
  "orDragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அல்லது ஒரு இடத்திலிருந்து மற்றொரு இடத்திற்கு நகர்த்தி வைத்தல்"])},
  "CSVFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV கோப்பு"])},
  "fileUploadedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கோப்பு வெற்றிகரமாக பதிவேற்றப்பட்டது "])},
  "checkCSVDetailsBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றிய CSV கோப்பு விவரங்களை கீழே பார்க்கவும்"])},
  "wantToSendInvitePopMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேர்ந்தெடுக்கப்பட்ட கல்லூரி(களுக்கு) அழைப்பை அனுப்ப விரும்புகிறீர்களா?"])},
  "wantToSendStudentInvitePopMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேர்ந்தெடுக்கப்பட்ட மாணவர்களுக்கு(களுக்கு) அழைப்பை அனுப்ப விரும்புகிறீர்களா?"])},
  "sendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழைப்பிதழ் அனுப்பவும்"])},
  "emailInvitationsInitiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல் அழைப்புகள் தொடங்கப்பட்டன"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முடிந்தது"])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தகவல் இல்லை"])},
  "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொழில்நுட்பம்"])},
  "trainingModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சி தொகுதி"])},
  "trainingLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சி நிலை"])},
  "specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சிறப்பு பாடம்"])},
  "yearOfStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்பு ஆண்டு"])},
  "modeOfDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["டெலிவரி முறை"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கால அளவு"])},
  "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சான்றிதழ்"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பு"])},
  "offeredSubsidizedCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தகுதியுள்ள மாணவர்களுக்கு மானியம்/தள்ளுபடி விலையில் வழங்கப்படுகிறது"])},
  "bulkImportStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்தமாக இறக்குமதி செய்யும் மாணவர்கள்"])},
  "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கோரப்பட்டது"])},
  "collegeApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி அங்கீகரிக்கப்பட்டது"])},
  "collegeRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி நிராகரிக்கப்பட்டது"])},
  "knowledgePartnerPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அறிவு கூட்டாண்மை/இரு வழி தகவல் தொடர்பு நிலுவையிலுள்ளது"])},
  "knowledgePartnerApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அறிவு கூட்டாண்மை/இரு வழி தகவல் தொடர்பு அங்கீகரிக்கப்பட்டது"])},
  "knowledgePartnerRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அறிவு கூட்டாண்மை/இரு வழி தகவல் தொடர்பு நிராகரிக்கப்பட்டது"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விரைவில்"])},
  "caste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஜாதி"])},
  "provisional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்காலிகமானது"])},
  "offlineCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆஃப்லைன் படிப்புகள்"])},
  "onlineCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆன்லைன் படிப்புகள்"])},
  "courseProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்பு முன்னேற்றம்"])},
  "progressPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முன்னேற்ற சதவீதம்"])},
  "courseCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" படிப்பு முடிவுற்றது"])},
  "assessmentCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு  முடிவுற்றது"])},
  "issueCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சான்றிதழ் வழங்கவும்"])},
  "viewCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சான்றிதழைப் பார்க்கவும்"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முடிக்கவும்"])},
  "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கவனிக்கவும்"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆம்"])},
  "noEnrolledCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவுசெய்யப்பட்ட படிப்புகள் இல்லை"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பார்க்கவும்"])},
  "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு செய்யவும்"])},
  "enrollOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆன்லைனில் பதிவு செய்யவும்"])},
  "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வேலை தலைப்பு"])},
  "openings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆரம்பம்"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விண்ணப்பிக்கவும்"])},
  "openPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["திறந்த நிலைகள்"])},
  "viewProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முன்னேற்றத்தைக் காணவும்"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முன்னேற்றம்"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதுப்பிக்கவும்"])},
  "updateCourseProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடநெறி முன்னேற்றத்தினை புதுப்பிக்கவும்."])},
  "skillOfferings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["திறன் சலுகைகள்"])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் பார்க்க"])},
  "internshipTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பணியிடை பயிற்சி தலைப்பு"])},
  "internshipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சி வகை (மெய்நிகர் /நபர்)"])},
  "eligibleCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தகுதியான அளவுகோல்கள்"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆரம்ப நாள்"])},
  "durationMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கால அளவு (மாதங்கள்)"])},
  "noOfOpenings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["திறவுகளின் எண்ணிக்கை"])},
  "lastDateOfApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விண்ணப்பத்தின் கடைசி தேதி"])},
  "skillsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேவையான திறன்கள்"])},
  "freeOrPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இலவசம்/கட்டணம்"])},
  "ifPaidStipend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பணம் செலுத்தியிருந்தால், உதவித்தொகை விவரங்கள்"])},
  "otherPerks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பிற சலுகைகள்"])},
  "aboutTheInternship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பணியிடை பயிற்சி பற்றி"])},
  "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கூடுதல் தகவல்"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இடம்"])},
  "taluk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தாலுகா"])},
  "educationalQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்வித் தகுதி"])},
  "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஊதியம்"])},
  "jobDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வேலை விவரம்"])},
  "trainingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சி தலைப்பு"])},
  "trainingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சி விவரம்"])},
  "mentorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வழிகாட்டியின் பெயர்"])},
  "mentorEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வழிகாட்டியின் மின்னஞ்சல்"])},
  "mentorPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வழிகாட்டியின் தொலைபேசி எண்"])},
  "designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதவி"])},
  "availabilityHoursWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கிடைக்கப்பெற்ற (மணி/வாரம்)"])},
  "modeVirtualInPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயன்முறை (மெய்நிகர் / நேரில்)"])},
  "linkedinProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin சுயவிவர URL"])},
  "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொழிகள்"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிறைவு"])},
  "certificationInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சான்றளிப்பு நடந்து கொண்டிருக்கிறது"])},
  "targetAudience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இலக்கு பார்வையாளர்கள்"])},
  "trainingPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சி கூட்டாளர்"])},
  "moreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கூடுதல் தகவல்கள்"])},
  "whatYouLearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் என்ன கற்றுக் கொள்வீர்கள்"])},
  "courseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடத்தின் உள்ளடக்கம்"])},
  "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேவைகள்"])},
  "noJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்போதைய நிறுவனத்தில் வேலை இல்லை."])},
  "noCompaniesInSector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்போதைய துறையில் நிறுவனங்கள் இல்லை."])},
  "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பணம் செலுத்த முடியவில்லை"])},
  "paymentFailedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் பணம் செலுத்த முடியவில்லை."])},
  "paymentSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பணம் செலுத்துதல் வெற்றிகரமாக உள்ளது"])},
  "paymentSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் கட்டணம் செயலாக்கப்பட்டது. பரிவர்த்தனைகளின் விவரங்கள் கீழே சேர்க்கப்பட்டுள்ளன"])},
  "transactionTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பரிவர்த்தனை நேரம்"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பரிவர்த்தனை ஐடி"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொகை"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அச்சிடுக"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முகப்புக்குத் திரும்பு"])},
  "2022GraduatesRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 பட்டதாரிகளின் பதிவு"])},
  "aadhaarNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆதார் எண்"])},
  "uploadProvisionalDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்காலிக பட்டப்படிப்பு சான்றிதழை பதிவேற்றவும்"])},
  "finishingSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முடித்த பள்ளி"])},
  "finishingSchoolEnrolments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பள்ளி சேர்க்கைகளை முடித்தல்"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பெயர்"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விளக்கம்"])},
  "enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவுகள்"])},
  "waitingForApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒப்புதலுக்கு காத்திருக்கிறது"])},
  "mandatoryCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கட்டாய படிப்புகள்"])},
  "facultyMapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty Mapping"])},
  "finishingSchoolDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நான் முதல்வன் தளம் தமிழ்நாட்டின் இளைஞர்கள் விருப்பத் திறன்களைப் பெறுவதற்கான நிலையான வாய்ப்புகளை உருவாக்குவதையும், நிலையான வாழ்வாதாரத்தை உருவாக்கும் வழிமுறைகளுடன் அவர்களுக்கு ஆதரவளிப்பதையும் நோக்கமாகக் கொண்டுள்ளது. அவர்களை தொழில்துறைக்கு தயார்படுத்தும் அறிவு. பள்ளிப் பயிற்றுவிப்பாளர்கள் தங்கள் வாழ்க்கைத் திறன் வேலைவாய்ப்புத் திறன்கள், செயல்பாட்டு மற்றும் பேசும் ஆங்கிலத் திறன்களை வளர்த்துக்கொள்ள முடியும். அவர்களின் அன்றாட நிபுணத்துவ வாழ்க்கையின் ஒரு முக்கிய பகுதியாக இருக்கும்"])},
  "pendingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிலுவையிலுள்ள ஒப்புதல்"])},
  "pleaseWaitTillApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒப்புதல் வரை காத்திருக்கவும்"])},
  "courseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாட வகை"])},
  "stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஸ்ட்ரீம்"])},
  "freeCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இலவச பாடநெறி"])},
  "paidCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கட்டண பாடநெறி"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆஃப்லைன்"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிகழ்நிலை"])},
  "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கலப்பின"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் படிக்க"])},
  "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கிளை"])},
  "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவணை"])},
  "editStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர் திருத்தவும்"])},
  "unverifiedStudentsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரிபார்க்கப்படாத மாணவர் எண்ணிக்கை"])},
  "semesterRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செமஸ்டர் புலம் தேவை மற்றும் எண்ணாக இருக்க வேண்டும்"])},
  "updateAndVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதுப்பித்து, சரிபார்த்து அழைப்பை அனுப்பவும்"])},
  "addAndVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழைப்பைச் சேர்க்கவும், சரிபார்க்கவும் மற்றும் அனுப்பவும்"])},
  "finishStudentVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர் சரிபார்ப்பை முடிக்கவும்"])},
  "verificationCompleteRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர் சரிபார்ப்புக் கோரிக்கைகள் நிறைவடைந்தன."])},
  "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்களிடம் உள்ளது"])},
  "pendingVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரிபார்ப்பு கோரிக்கைகள் நிலுவையில் உள்ளன"])},
  "pendingStudentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரிபார்ப்பை முடிக்கும் முன் நிலுவையில் உள்ள மாணவர்களைச் சரிபார்க்கவும்."])},
  "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரி"])},
  "collegesVerifiedCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரிகளின் சரிபார்க்கப்பட்ட எண்ணிக்கை"])},
  "pendingVerificationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிலுவையில் உள்ள சரிபார்ப்பு"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரிபார்க்கப்பட்டது"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வடிப்பான்கள்"])},
  "rollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு எண்"])},
  "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான மின்னஞ்சல்"])},
  "invalidMobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான மொபைல் எண்"])},
  "deleteStudentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இந்த மாணவனை நிச்சயமாக நீக்க விரும்புகிறீர்களா?"])},
  "noStudentsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர்கள் இல்லை"])},
  "verifyStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர்களை சரிபார்க்கவும்"])},
  "exportCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஏற்றுமதி CSV"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரிபார்க்கவும்"])},
  "bulkUploadConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் நிச்சயமாகச் சரிபார்க்க விரும்புகிறீர்களா"])},
  "studentDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர் நீக்கப்பட்டார்"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மீண்டும்"])},
  "noSuchOfferings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இந்தத் தேர்வில் அத்தகைய சலுகை இல்லை"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விவரங்கள்"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வீடு"])},
  "yearOfGraduation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பட்ட படிப்பு முடித்த ஆண்டு"])},
  "finishingSchoolRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பள்ளிப் பதிவை முடித்தல்"])},
  "finishingSchoolPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முடித்த பள்ளி பங்குதாரர்"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["டாஷ்போர்டு"])},
  "industries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொழில்கள்"])},
  "organisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அமைப்பின் பெயர்"])},
  "industryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொழில் வகை"])},
  "primaryContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை தொடர்பு"])},
  "primaryContact2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை தொடர்பு 2"])},
  "hasInternships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இன்டர்ன்ஷிப் உள்ளது"])},
  "hasJobOpenings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வேலை வாய்ப்பு உள்ளது"])},
  "internshipTypeOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இன்டர்ன்ஷிப் வகை"])},
  "stipend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உதவித்தொகை"])},
  "qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தகுதி"])},
  "addJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வேலையைச் சேர்க்கவும்"])},
  "addInternship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இன்டர்ன்ஷிப்பைச் சேர்க்கவும்"])},
  "addMentorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வழிகாட்டுதலைச் சேர்க்கவும்"])},
  "internships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சிகள்"])},
  "mentorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வழிகாட்டுதல்"])},
  "addTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சியைச் சேர்க்கவும்"])},
  "trainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சிகள்"])},
  "editCollege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரியைத் திருத்தவும்"])},
  "spocName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPOC பெயர்"])},
  "updateCollege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரியைப் புதுப்பிக்கவும்"])},
  "addCollege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரியைச் சேர்"])},
  "collegeAddedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி வெற்றிகரமாக சேர்க்கப்பட்டது"])},
  "collegeUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி வெற்றிகரமாக புதுப்பிக்கப்பட்டது"])},
  "pleaseFillAllFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனைத்து புலங்களையும் நிரப்பவும்"])},
  "zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மண்டலம்"])},
  "collegeCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி குறியீடு"])},
  "pincode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அஞ்சல் குறியீடு"])},
  "doYouWantToImportStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர்களை இறக்குமதி செய்ய விரும்புகிறீர்களா"])},
  "studentsUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றம் தொடங்கப்பட்டது மாணவர் பதிவேற்ற வரலாற்றில் நிலையைச் சரிபார்க்கவும்"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேர்ந்தெடு"])},
  "bulkStudentsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்த மாணவர்களின் வரலாறு"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செயல்பாட்டில் உள்ளது"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றப்பட்டது"])},
  "readyToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றம் தயார்"])},
  "invalidCollegeCodesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான கல்லூரிக் குறியீடுகளின் எண்ணிக்கை"])},
  "invalidBranchCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான கிளை எண்ணிக்கை"])},
  "invalidRollNoCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான ரோல் எண்ணிக்கை இல்லை"])},
  "successRecordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெற்றிப் பதிவுகளின் எண்ணிக்கை"])},
  "failedRecordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தோல்வியடைந்த பதிவுகளின் எண்ணிக்கை"])},
  "uploadedFileDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றப்பட்ட கோப்பு விவரங்கள்"])},
  "invalidCollegeCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான கல்லூரி குறியீடுகள்"])},
  "invalidRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான ரோல் எண்"])},
  "invalidBranchIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தவறான கிளை ஐடிகள்"])},
  "unVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரிபார்க்கப்படவில்லை"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை மாற்று"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை புதுப்பிக்கவும்"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்போதைய கடவுச்சொல்"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதிய கடவுச்சொல்"])},
  "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சலைப் புதுப்பிக்கவும்"])},
  "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதிய மின்னஞ்சல்"])},
  "updatePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொலைபேசியைப் புதுப்பிக்கவும்"])},
  "newPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதிய தொலைபேசி எண்"])},
  "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயன்முறை"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கூட்டு"])},
  "sem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செம்"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அகற்று"])},
  "studentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவன் பெயர்"])},
  "finishAllocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒதுக்கீடுகளை முடிக்கவும்"])},
  "temporaryRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்காலிக ரோல் எண்"])},
  "proceedToCoursera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடநெறிக்குச் செல்லவும்"])},
  "proceedToInfosys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இன்ஃபோசிஸுக்குச் செல்லுங்கள்"])},
  "accessCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அணுகல் படிப்பு"])},
  "proceedToMicrosoft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மைக்ரோசாப்ட் தொடரவும்"])},
  "knowledgePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அறிவு கூட்டாளி"])},
  "allocated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒதுக்கப்பட்டது"])},
  "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["குழுசேர்ந்தார்"])},
  "updateExternalAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெளிப்புற மதிப்பீட்டைப் புதுப்பிக்கவும்"])},
  "absentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இல்லாதது"])},
  "assessment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு 1"])},
  "assessment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு 2"])},
  "assessment3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு 3"])},
  "assessment4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு 4"])},
  "assessment5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு 5"])},
  "updateAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீட்டைப் புதுப்பிக்கவும்"])},
  "externalAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு"])},
  "Assessment Rubics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீட்டு முறைகள்"])},
  "finishExternalAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெளிப்புற மதிப்பீட்டை முடிக்கவும்"])},
  "finishExternalAssessmentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெளிப்புற மதிப்பீட்டை நிச்சயமாக முடிக்க விரும்புகிறீர்களா?"])},
  "externalAssessmentFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெளிப்புற மதிப்பீடு முடிந்தது"])},
  "enterPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொலைபேசி எண்ணை உள்ளிடவும்"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொலைபேசி எண்"])},
  "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடரவும்"])},
  "eoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EOI"])},
  "contactPersonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர்பு நபர் பெயர்"])},
  "contactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர்பு நபரின் மின்னஞ்சல்"])},
  "contactPersonPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர்பு நபரின் தொலைபேசி"])},
  "registrationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு ஆவணம்"])},
  "proposalDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முன்மொழிவு ஆவணம்"])},
  "declarationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பிரகடன ஆவணம்"])},
  "submittedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அன்று சமர்ப்பிக்கப்பட்டது"])},
  "uploadRegistrationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு ஆவணத்தை பதிவேற்றவும்"])},
  "uploadOnlyPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF ஐ மட்டும் பதிவேற்றவும்"])},
  "uploadProposalDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முன்மொழிவு ஆவணத்தைப் பதிவேற்றவும்"])},
  "uploadDeclarationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பிரகடன ஆவணத்தைப் பதிவேற்றவும்"])},
  "sepcialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சிறப்பு"])},
  "sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["துறைகள்"])},
  "expressionOfInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆர்வத்தின் வெளிப்பாடு"])},
  "viewDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பார்க்க / பதிவிறக்கவும்"])},
  "reviewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆய்வு பாடநெறி"])},
  "reviewCourseToViewCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சான்றிதழைப் பார்க்க பாடத்தை மதிப்பாய்வு செய்யவும்"])},
  "assessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடுகள்"])},
  "courseraAssessmentUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடநெறி மதிப்பீடு பதிவேற்றம்"])},
  "courseraAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடநெறி மதிப்பீடுகள்"])},
  "uploadCourseraAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடநெறி மதிப்பீட்டைப் பதிவேற்றவும்"])},
  "doYouWantToUploadCourseraAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடநெறி மதிப்பீட்டை(களை) பதிவேற்ற விரும்புகிறீர்களா"])},
  "courseraAssessmentUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றம் தொடங்கப்பட்டது பாடநெறி மதிப்பீட்டு பதிவேற்ற வரலாற்றில் நிலையைச் சரிபார்க்கவும்"])},
  "uploadMicrosoftAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மைக்ரோசாஃப்ட் மதிப்பீட்டைப் பதிவேற்றவும்"])},
  "doYouWantToUploadMicrosoftAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மைக்ரோசாஃப்ட் மதிப்பீட்டை(களை) பதிவேற்ற விரும்புகிறீர்களா"])},
  "microsoftAssessmentUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றம் தொடங்கப்பட்டது மைக்ரோசாஃப்ட் மதிப்பீட்டின் பதிவேற்ற வரலாற்றில் நிலையைச் சரிபார்க்கவும்"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றவும்"])},
  "partiallyUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பகுதி பதிவேற்றப்பட்டது"])},
  "placementStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வேலை வாய்ப்பு நிலை"])},
  "microsoftAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மைக்ரோசாஃப்ட் மதிப்பீடுகள்"])},
  "microsoftUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மைக்ரோசாஃப்ட் புதுப்பிப்பு"])},
  "assessmentSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீட்டு தொடர்"])},
  "assessmentRubrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடு ரூப்ரிக்ஸ்"])},
  "refAssessmentRubrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள் மதிப்பெண்களைப் பதிவேற்றும் முன் மதிப்பீட்டு ரூப்ரிக்ஸைப் பார்க்கவும்"])},
  "updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதுப்பிப்புகள்"])},
  "infosysAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இன்ஃபோசிஸ் மதிப்பீடுகள்"])},
  "uploadInfosysAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இன்ஃபோசிஸ் மதிப்பீட்டைப் பதிவேற்றவும்"])},
  "doYouWantToUploadInfosysAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் இன்ஃபோசிஸ் மதிப்பீட்டை(களை) பதிவேற்ற விரும்புகிறீர்களா"])},
  "infosysAssessmentUploadInitiatedCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவேற்றம் தொடங்கப்பட்டது இன்ஃபோசிஸ் மதிப்பீட்டு பதிவேற்ற வரலாற்றில் உள்ள நிலையை சரிபார்க்கவும்"])},
  "assessmentPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீட்டு சதவீதம்"])},
  "addFaculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரியர்களைச் சேர்க்கவும்"])},
  "facultyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரிய தரவு"])},
  "noFacultyData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரியர் தரவு இல்லை"])},
  "facultyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரிய விவரங்கள்"])},
  "updateFacultyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரியர் விவரங்களைப் புதுப்பிக்கவும்"])},
  "collegeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரி தரவு"])},
  "principalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை தரவு"])},
  "principalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை விவரங்கள்"])},
  "principalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை பெயர்"])},
  "principalEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை மின்னஞ்சல்"])},
  "principalPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை தொலைபேசி"])},
  "updatePrincipalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முதன்மை விவரங்களைப் புதுப்பிக்கவும்"])},
  "internalAssessmentMarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள் மதிப்பீட்டு மதிப்பெண்கள்"])},
  "externalAssessmentMarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெளி மதிப்பீட்டு மதிப்பெண்கள்"])},
  "placementOfficerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வேலை வாய்ப்பு அதிகாரி விவரங்கள்"])},
  "updatePlacementOfficerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வேலை வாய்ப்பு அதிகாரி விவரங்களைப் புதுப்பிக்கவும்"])},
  "costPerStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒரு மாணவருக்கு செலவு"])},
  "enterOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP ஐ உள்ளிடவும்"])},
  "foundMultipleUsersPleaseEnterRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பல பயனர்களைக் கண்டறிந்துள்ளனர் கடைசி 4 இலக்கங்களின் ரோல் எண்ணை உள்ளிடவும்"])},
  "verifyOTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTPயைச் சரிபார்க்கவும்"])},
  "studentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவர் உள்நுழைவு"])},
  "last4DigitsRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்லூரிப் பட்டியலின் கடைசி 4 இலக்கங்கள்"])},
  "deleteFacultyConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரியர்களை நிச்சயமாக நீக்க விரும்புகிறீர்களா?"])},
  "facultyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரிய ஐடி"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செயலில்"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொகு"])},
  "courseOption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Selection 1"])},
  "courseOption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Selection 2"])},
  "courseOption3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Selection 3"])},
  "emisNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMIS எண்"])},
  "umisNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UMIS எண்"])},
  "transferStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மாணவரை மாற்றவும்"])},
  "passedOutYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பட்டம் பெற்ற ஆண்டு"])},
  "academicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்வி ஆண்டு"])}
}